@use 'ui/styles/variables' as vars;

.tabsContainer {
  position: relative;

  .scrollButton {
    position: absolute;
    top: 0.25rem;
  }

  .scrollButton:first-of-type {
    left: -1rem;
    background: linear-gradient(to left, transparent, 40%, var(--background-beige))
  }

  .scrollButton:last-of-type {
    right: -1rem;
    background: linear-gradient(to right, transparent, 40%, var(--background-beige))
  }
}

.Tabs ul {
  background-color: #fff;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  background-color: var(--background-beige);
  height: 48px;
  align-items: center;
  min-width: 100%;
  scrollbar-width: none;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & li {
    list-style: none;
    height: 100%;
    white-space: nowrap;
  }

  .Tab {
    display: flex;
    text-align: center;
    padding: 0px 16px 0px 16px;
    color: var(--core-ink);
    height: 100%;
    align-items: center;
    gap: 4px;

    @include vars.mediaQuery('md') {
      padding: 0px 24px 0px 24px;
    }

    &:first-child {
      scroll-snap-align: start;
    }

    &:last-child {
      scroll-snap-align: end;
    }

    &:focus-visible {
      outline: none;
      outline-offset: -5px;
      border-radius: 4px;
      background-color: var(--alert-dangerBackground);
    }

    &.active {
      font-weight: 600;
      border-bottom: 3px solid var(--alert-danger);
    }

    small {
      font-size: 1em;
      color: var(--ui-mutedText);
    }
  }
}

@include vars.mediaQuery('md') {
  .Tabs.vertical ul {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding: 0.5rem;

    li {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .Tab {
      padding: 0.25rem 1rem;

      &.active {
        border-bottom: none;
        border-left: 4px solid var(--alert-danger);
      }
    }
  }
}