@use 'ui/styles/variables' as vars;

.Layout {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  max-width: 1230px;
  padding: 16px;
  box-sizing: border-box;

  label {
    font-size: 0.875rem;
    color: var(--ui-mutedText);
    text-transform: uppercase;
  }
}

.photoLabel {
  display: block;
}

.Row {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 1.75rem;
  gap: 1rem;
}

.MobileColumn {
  flex-direction: column;

  @include vars.mediaQuery('md') {
    flex-direction: row;
  }
}

.editImage {
  display: flex;
  flex: 1;
  gap: 1rem;
}