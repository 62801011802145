.ClippingsList {
  padding: 24px 12px 0px;
}

.NoResults {
  width: fit-content;
  margin: 3rem auto;
  padding: 1em;
  background: var(--background-lighter);
  border: 1px solid var(--ui-divider);
}

.SearchResultActions button {
  margin: 0 auto;
}

/*
This will create a grid of 1, 2, 3, 4, or 6 columns (or even 8 or 12 if we wanted to)
where each column is at least 200px.
This allows us to load 24 results per page, and always have the page fill an even row.
*/
$minCardWidth: 200px;
$maxCardWidth: 300px; // max for 6 col layout
$cardGap: 16px;

.ClippingsColumns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max($minCardWidth, 21%), 1fr));
  gap: $cardGap;
  margin: auto;

  @media (min-width: (($minCardWidth + 4) * 6 + $cardGap * 5)) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    width: ($maxCardWidth * 6 + $cardGap * 5);
    max-width: 100%;
  }
}

.ClippingTagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.ClippingCard {

  .ClippingImage {
    // fallback to 1/1 while image is loading
    // aspect-ratio: auto 1/1;
    border-radius: 4px 4px 0 0;
    color: inherit;

    &:hover {
      filter: brightness(1.07);
    }
  }
}