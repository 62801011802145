.Container {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2em;

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    li {
      display: inline;

      &:last-of-type {
        color: var(--ui-bodyText);
        font-weight: bold;
      }

      &::before {
        content: '›';
        color: var(--ui-mutedText);
        font-weight: normal;
        padding: 0 6px;
      }

      &:first-child::before {
        content: '';
        padding: 0;
      }
    }
  }
}

.Spacer {
  color: var(--ui-divider);
}