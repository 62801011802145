@use "ui/styles/variables" as vars;

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f3eb;
  padding: 10px;
  border: 1px solid var(--ui-divider);
  margin-bottom: 0;
  align-items: center;
  margin: -16px -16px 0 -16px;

  @include vars.mediaQuery('md') {
    border-radius: 6px;
    margin: 0 0 16px 0;
  }

  @media print {
    display: none;
  }

}

.Button {
  justify-content: center;
  text-align: center;

  @include vars.mediaQuery('md') {
    margin-top: 0;
    width: auto;
  }

}

.Title {
  position: relative;
  font-weight: bold;
  margin-right: 8px;
  font-size: 18px;
  color: var(--ui-link);

  .Deny & {
    color: var(--core-ink);
  }

  @include vars.mediaQuery('md') {
    font-size: 24px;
  }
}

.ButtonContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.Description {
  text-align: right;
  font-weight: bold;
  align-self: center;
  margin-right: 8px;
  display: none;

  @include vars.mediaQuery('lg') {
    display: inline;
  }

}

.ShowDesktop {
  display: none;

  @include vars.mediaQuery('sm') {
    display: inline;
  }

}

.ShowMobile {
  display: inline;

  @include vars.mediaQuery('sm') {
    display: none;
  }

}

.TitleContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}