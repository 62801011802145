@use 'ui/styles/variables' as vars;

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  font-family: var(--theme-font-family);

  &.rightPress {
    @include vars.mediaQuery('md') {
      background-image: url(/i/rightpress.png);
      background-position: right top;
      background-repeat: repeat-y;
    }
  }

  &.hideHeader {
    border-top: 4px solid var(--core-ink);
  }

  &.gray {
    background-color: var(--background-gray);

    @include vars.mobileOnly() {
      background-color: white;
    }

    .ContentFray {
      @include vars.mobileOnly() {
        display: none;
      }
    }
  }
}

.ContentFray {
  background-image: url('/i/pfray@2x.png');
  background-size: 10px 14px;
  background-repeat: repeat-x;
  width: 100%;
  height: 7px;
  background-position: 0 -7px;
  max-width: map-get(vars.$screenSize, 'md');
  align-self: center;

  &.fullWidth {
    max-width: 100%;
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 16px;

  @include vars.mediaQuery('sm') {
    padding: 24px;
  }

  @include vars.mediaQuery('md') {
    align-self: center;
    padding: 8px 32px 32px;
    max-width: 1320px;
    width: 100%;
    margin-top: 24px;
  }

  &.fullWidth {
    padding: 0;

    @include vars.mediaQuery('md') {
      align-self: center;
      max-width: 100%;
      width: 100%;
      margin-top: 0;
    }
  }

  &.gray {
    @include vars.mediaQuery('md') {
      max-width: map-get(vars.$screenSize, 'md');
      background-color: white;
      padding: 8px 100px 100px;
    }
  }
}