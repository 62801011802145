@use 'ui/styles/variables' as vars;

.Main {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 600px;

  @include vars.mediaQuery('md') {
    width: 100%;
    padding: 0 !important;
  }
}

.DetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;

  @include vars.mediaQuery('md') {
    border-right: 1px solid #d3d3d3;
    padding-right: 50px;
    width: 66%;
    min-height: 386px;
  }
}

.AboutContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  align-items: flex-start;
  justify-content: flex-start;

  @include vars.mediaQuery('md') {
    padding-left: 50px;
    width: 33%;
  }
}

.Title {
  font-size: 1.25em;
  color: var(--theme-palette-text-primary);
  font-weight: bold;
  margin: 8px 0;
}

.ExtraTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--theme-palette-text-extra);
}

.NewspageContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Label {
  font-size: 16px;
  font-weight: bold;
  color: #696969;
  min-width: 150px;
}

.ExtraLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-palette-text-extra);
}

.Details {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.DetailLine {
  display: flex;
  flex-direction: row;
}


.ClippingList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 32px;
  gap: 16px;
}

.Section {
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 16px;
}

.TopSection {
  justify-content: center;
  background-color: #f6fbfe;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @include vars.mediaQuery('md') {
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
    padding-top: 60px;
  }
}

.BottomSection {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @include vars.mediaQuery('md') {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1320px;
  }
}

.TrialPill {
  margin-right: 24px;
  align-self: flex-end;
}

.SearchContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.Term {
  font-weight: normal;
}

.PublicationLink {
  color: var(--theme-palette-text-primary);
  font-size: 16px;
  font-weight: normal;
}

.Tile {
  width: 100%;

  @include vars.mediaQuery('md') {
    width: calc(50% - 16px) !important;
    /* Figure out why this is needed */
  }

  @include vars.mediaQuery('lg') {
    width: calc(33% - 16px) !important;
  }
}

.TrialButton {
  margin-bottom: 8px;

  @include vars.mediaQuery('md') {
    margin-bottom: 0;
  }
}

.AdvertisementContainer{
  display: flex;
  justify-content: center;
}

.Advertisment{
  max-width: 728px;
    max-height: 90px;
  
    @include vars.mobileOnly() {
      max-width: 320px;
      max-height: 50px;
    }
}