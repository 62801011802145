@use 'ui/styles/variables' as vars;

.SocialLink {
  display: flex;
  align-items: center;
  padding: 0 .5rem;

  &:hover {
    opacity: .7;
  }

}

.SocialRow {
  list-style-type: none;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.NavLinkContainer {
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 3.5rem;

  .hamburger {
    display: flex;
  }

  .desktopNav {
    display: none;
  }

  @media print {
    display: none;
  }

  //larger than tablet don't show hamburger
  @include vars.mediaQuery('lg') {
    .hamburger {
      display: none;
    }

    .desktopNav {
      display: flex;
    }
  }
}

.LinkItem {
  text-decoration: none;
  color: #fff;

  .notMainSite {
    text-transform: uppercase;
  }
}

.NavLink {
  padding-left: 16px;
  margin: 0px 2px;
  text-decoration: none;
  color: #fff;
  font-family: var(--font-family);
  line-height: 40px;
  border-radius: 500px !important;
  font-size: 1em;
  font-weight: 400;
  user-select: none;
  display: inline-block;

  &:hover {
    background-color: rgba(239, 239, 239, 0.1);
    color: #fff;
    text-decoration: none;
  }


}

.MenuButton {
  background: none;
  color: #fff;
  height: 40px;
  text-align: left;
  padding: 0px 16px 0px 0px;
  margin-left: 4px;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;

  &:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
  }

}

.BottomLinks {
  font-size: 14px;
  line-height: 1rem;
  font-family: var(--font-family);
  background-color: var(--background-beige);
  padding: 1rem 2rem;
  display: flex;

  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include vars.mediaQuery('lg') {
    justify-content: space-between;
  }

  a {
    font-weight: bold;
    color: var(--core-ink);

    &:hover {
      text-decoration: underline;
    }
  }

}

.MenuLinks {
  font-family: var(--font-family);
  line-height: 1.7rem;
  display: flex;
  flex-direction: column;

  @include vars.mediaQuery('lg') {
    flex-direction: row;
    flex-wrap: wrap;

    ul {
      border-right: 1px solid var(--ui-divider);
      border-bottom: 0px transparent !important;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--ui-divider);
    padding: 10px 20px;
    margin-bottom: 0px;
    background-color: var(--background-lighter);

    &:hover,
    &:focus-within {
      transform: scale(1.05);
      transition: transform .23s ease-in;
      box-shadow: 0 0 11px 2px rgba(0, 0, 0, .2);
      border-color: #fff;
    }
  }

  ul:last-of-type {
    border: 0px transparent;
  }

  ul,
  li {
    text-decoration: none;
    list-style: none;
  }

  li:first-of-type {
    font-weight: bold;
  }

  li {
    overflow-wrap: no-wrap;
    display: flex;
    padding: 0px;
    cursor: pointer;

    a {
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;
      color: var(--core-ink);
      border-color: transparent;
      border-radius: .25rem;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        outline: 0;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        background-color: #e1e1e1;
        border-color: #e1e1e1;
      }
    }
  }

}

.MenuClose {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
  margin: .5em;
  z-index: 1;
  background: none;
  border-radius: .25em;
  fill: var(--ui-mutedText);

  @include vars.mediaQuery('lg') {
    display: block;
  }

  &:hover,
  &:focus {
    outline: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #e1e1e1;
    border-color: #e1e1e1;
  }

}

.HomeMenuLinks {
  background-color: #fff !important;

  @include vars.mediaQuery('lg') {
    align-items: flex-end;
  }

  @include vars.mediaQuery('xl') {
    li {
      padding-left: 77px !important;
    }
  }

}

.MenuTrigger {
  height: 100%;
  max-height: 3.5rem;
  aspect-ratio: 1 / 1;
  background-color: var(--core-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

// Menu

.Menu {
  user-select: none;
  position: absolute;
  background-color: var(--background-lighter);
  transition: height 500ms;
  bottom: 0px;
  left: 0px;
  width: 100%;
  list-style: none;
  padding: 0;
  display: none;

  &.open {
    display: block;
    height: max-content;
  }

  top: 100%;
  margin: 0 auto;
  padding: 0px;
  box-sizing: border-box;
  color: #333;
  overflow: hidden;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  z-index: 200;
}

@keyframes rotateR {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(15px) rotate(45deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotateL {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(-15px) rotate(-45deg);
  }
}

.Hamburger {
  transition: .3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 21px;

  >.bar {
    min-height: 3px;
    width: 24px;
    display: block;
    position: relative;
    background-color: #fff;
    transition: .3s;
  }

  &.open {
    >.bar {
      &:nth-of-type(1) {
        transform: translateY(8px) rotate(135deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
        transform: translateY(10px)
      }

      &:nth-of-type(3) {
        transform: translateY(-10px) rotate(-135deg);
      }
    }
  }
}