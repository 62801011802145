@use 'ui/styles/variables' as vars;

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include vars.mediaQuery('lg') {
    flex-direction: row;
  }
}

.Heading {
  color: var(--core-red);
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 1em;
}

.ResetContainer {
  flex: 1 1 100%;
  width: auto;
}

.ResetContainerInner {
  border-radius: 0.25em;
  border: 1px solid var(--background-gray);
  flex: 1 1 100%;
  max-width: 420px;
  margin: 1.5rem .25em;
  padding: 1em;

  @include vars.mediaQuery('md') {
    margin: 1.5em auto;
    padding: 1.5em;
  }
}