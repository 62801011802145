@use 'ui/styles/variables' as vars;

.ClippingsTabs {
  display: flex;
  flex-direction: row;
  order: -1;
  white-space: nowrap;

  h1 {
    font-size: unset;
    margin: unset;
    font-weight: bold;
    line-height: inherit;
  }
}

@include vars.mobileOnly() {

  // hide for mobile when search is focused
  :global(.mobileSearchFocused)+.ClippingsTabs {
    display: none;
  }
}

.Tab {
  border-bottom: 3px solid transparent;
  padding: 16px 16px;
}

.active {
  border-bottom-color: var(--core-red);
  font-weight: bold;
}