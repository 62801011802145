.ThirdPartyAuthButton {
  text-align: right;
  padding: 6px 12px;
  display: block;

  strong {
    color: var(--alert-warning);
    display: block;
    font-size: 16px;
    line-height: 1;
  }

  span {
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
  }

}