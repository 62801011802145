.ImageContainer {
  position: relative;
  height: 260px;
  width: 100%;
  background-color: var(--background-gray);
}

.ClipImage {
  object-fit: contain;
}

.Count {
  font-size: 14px;
  font-weight: normal;
  color: var(--ui-mutedText);

  &.danger {
    color: var(--alert-danger);
  }
}

.Details {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-weight: light;
  font-size: 1rem;
  margin-top: 8px;
  margin-bottom: 12px;
  gap: 5px;
}

.detailsStatus {
  color: var(--core-ink) !important;
  margin-left: 5px;
  font-size: 1rem;

  &:hover {
    text-decoration: none !important;
  }
}

.CheckContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: 14px;
}

.Note {
  font-size: 14px;
  line-height: 16px;
  color: var(--ui-mutedText);
  margin: 8px 0 16px 0;
}

.CurrentTags {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  width: 100%;
}

.NewTags {
  display: flex;
  align-items: center;
}

.Body {
  margin: 12px 0;
}

.ConfirmDeleteContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-content: flex-end;
}

.ConfirmDeleteMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .3);
}

.ConfirmDeleteInner {
  position: relative;
  z-index: 2;
  background: #fff;
}

.Footer {
  justify-content: space-between;

  >div:first-of-type {
    display: flex;
    gap: 8px;
  }
}