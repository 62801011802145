.pageContainer {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  padding: 16px;
}

.breadcrumbs {
  margin: 24px auto 0px auto;

  a {
    color: var(--ui-mutedText);
  }
}