.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  .top {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5 {
      text-align: center;
    }

    ul.years {
      margin: 0;
      padding: 0;
      columns: 4;
      -webkit-columns: 4;
      -moz-columns: 4;
      column-gap: 1em;


      li {
        align-items: center;
        list-style-type: none;
        text-align: center;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .Pagination {
      align-self: center;
      margin: 0 auto;
    }
  }



}