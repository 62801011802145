.TpAlert {
  margin-bottom: 16px;
  padding: 16px 0px;
  text-align: left;
}

.TpaAlertIcon {
  margin-right: 16px;
}

.TpaModalBody {
  text-align: center;
}

.BodySection {
  margin-bottom: 16px;
}

.WarningText {
  margin: 0px;
  font-size: 16px;
  padding-top: 5px;
}

.TpaFooter {
  text-align: center;
}

.TpaFooterText {
  width: 100%;
}