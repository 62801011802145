@use 'ui/styles/variables' as vars;

.PublicationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  gap: 16px;
  justify-content: center;
}

.PublicationCard {
  width: calc(20% - 16px);
}