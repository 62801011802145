.ClickButton {
  background-color: transparent;

  &:hover {
    background: #5b5b5b;
    color: #00466b;
    text-decoration: underline;
    cursor: pointer;
  }

}