.Private {
  width: 100%;
  text-align: center;

  .PrivateTitle {
    padding: 64px 0 4px 4px;
    font-weight: bolder;
    font-size: 1em;
    line-height: 1.2em;
    margin: 16px 0 0 0;
  }

  .PrivateText {
    font-size: .875em;
  }
}