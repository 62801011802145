@use 'ui/styles/variables' as vars;

.Pad {
  display: flex;
  flex-direction: column;
}

.Top {
  width: 100%;
  max-width: 100%;

  @include vars.mediaQuery('md') {
    max-width: 70%;
  }
}

.Middle {
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  justify-content: center;

  @include vars.mediaQuery('md') {
    flex-direction: row;
    justify-content: space-between;
  }

  @media print {
    flex-direction: column;
    text-align: center;
  }
}

.Bottom {
  display: flex;
  flex-direction: column;

  @include vars.mediaQuery('md') {
    padding: 0;
  }

  @media print {
    display: none;
  }

  .Breadcrumbs {
    justify-content: center;
  }
}

.BottomContain {
  @include vars.mediaQuery('md') {
    max-width: 66%;
  }
}

.BottomActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}

.Bullet {
  display: flex;
  margin: 0 16px;
}

.Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;

  @include vars.mediaQuery('md') {
    max-width: 66%;
  }
}

.SideBar {
  width: 100%;
  max-width: 30%;
}

.Title {
  font-size: 24px;
  color: var(--core-ink);
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;

  &.italic {
    font-style: italic;
  }

  @include vars.mediaQuery('md') {}
}

.MobileInfo {
  margin: 12px 0;
}

.ArticleBody {
  font-size: 15px;
  line-height: 24px;
  color: var(--core-ink);
}

.Citation {
  background: none;

  @media print {
    display: none;
  }
}

.Thumbnail {
  display: grid;
  grid-template-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  height: 356px;
  width: 256px;
  max-width: 100%;
}

.ImageContainer {
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  align-self: center;
  text-align: center;
}

.TrialButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NewspageImage {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.ViewAllButton {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.AdvertisementLdrContainer {
  display: flex;
  justify-content: center;
}

.AdvertisementLdr {
  width: 728px;
  height: 90px;

  @include vars.mobileOnly() {
    width: 320px;
    height: 50px;
  }
}

.AdvertisementMrec {
  width: 300px;
  height: 250px;

  @include vars.tabletOnly() {
    width: 125px;
    height: 125px;
  }
}