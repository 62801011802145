.Container {
  display: flex;
  position: relative
}

.ClippingCard {
  .Tag {
    font-size: .75rem;
  }

  .ClippingImage {
    // fallback to 1/1 while image is loading
    // aspect-ratio: auto 1/1;
    border-radius: 4px 4px 0 0;
    color: inherit;

    &:hover {
      filter: brightness(1.07);
    }
  }
}

.Overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.Gray {
  background-color: rgba(0, 0, 0, .5);
}

.Icon {
  height: 48px;
  width: 48px;
  margin: 12px;
}