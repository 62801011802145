.CopiedContainer {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CopiedText {
  font-size: 14px;
  line-height: 16px;
  color: var(--core-ink);
  margin-top: 40px;
}