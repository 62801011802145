@use "ui/styles/variables" as vars;

.Publisher {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: var(--ui-link);
  margin: 8px 0 0 0;
}

.Location {
  font-size: 15px;
  color: var(--core-ink);
  font-weight: bold;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 8px 0;
}

.Page {
  flex-basis: 100%;
}

.Container {
  display: flex;
  flex-direction: column;

  @include vars.mediaQuery("md") {
    padding: 0;
  }

}