.Buttons {
  display: flex;
  margin-top: 1rem;

  .Cancel {
    margin-left: 1em;
    margin-top: 0.75em;
  }
}

.Form {
  label {
    margin-top: 1rem;
  }
}