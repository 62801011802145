@use 'ui/styles/variables' as vars;

.TopicsWrapper {
  max-width: 960px;
  font-weight: bold;
  padding: 0.875rem 1rem;

  @include vars.mediaQuery("md") {
    padding: 0;
  }

  .Title {
    float: left;
    margin: 0;
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: var(--core-ink);
    white-space: nowrap;
  }

  .TopicsContainer {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  .Topic {
    float: left;
    margin-right: 0.5rem;
    margin-top: 0.125rem;
  }

  .TopicLink {
    color: var(--ui-peText) !important;

    &:hover {
      color: var(--ui-link);
    }

  }
}

.TopicsWrapper.vertical {
  .Title {
    float: none;
  }

  .Topic {
    float: none;
    margin-bottom: 1rem;
  }
}