@use 'ui/styles/variables' as vars;

.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 340px;
  margin-right: 0;

  a {
    &:focus-visible {
      outline-offset: -2px;
    }
  }
}

.Page {
  position: relative;
  width: 300px;
  text-align: center;
  align-self: center;
  margin-bottom: 40px;
  border: var(--alert-warning);
  height: 180px;
  overflow: hidden;
  border: none;

  &.highlight {
    height: auto;
    border: 1px solid var(--alert-warning);

    &::after {
      content: none;
    }
  }

  &:after {
    content: '""';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1.75rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  @include vars.mediaQuery('md') {
    margin-right: 40px;
    height: 400px;

    &:after {
      content: none;
    }
  }
}

.OverlayButton {
  color: #ffffff;
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 150px;
  font-size: 16px;
  background: rgba(0, 0, 0, .7);
  padding: 12px;
  border-radius: 20px;
  border: 1px solid var(--alert-danger);
  text-align: center;
  line-height: 30px;

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
  }

  @include vars.mediaQuery('md') {
    top: 30%;
  }

}