@use 'ui/styles/variables' as vars;

.NavLogo {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  color: #fff;
  line-height: 40px;

  &:hover {
    background-color: rgba(239, 239, 239, 0.1);
    color: #fff;
  }

  &:focus {
    color: #fff;
  }

  &:active {
    color: #fff;
  }

  button {
    &:focus {
      outline: 2px solid #fff;
    }
  }

  &.notMainSite {
    padding: 0px 1rem;
    font-size: 20px;
    display: none;

    @include vars.mediaQuery('lg') {
      display: block;
    }
  }

}