@use 'ui/styles/variables' as vars;

@include vars.mediaQuery('md') {
  .icon svg {
    visibility: visible !important;
    width: auto !important;
    margin-right: 0.25rem;
  }

  .navTitle {
    margin-left: 1.5rem;
  }
}

.icon svg {
  visibility: hidden;
  width: 0;
}

.navTitle {
  display: block;
  font-weight: 600;
  font-size: 1.25rem;
}