@use 'ui/styles/variables' as vars;

.pageContainer {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  padding: 16px;

  h1 {
    text-align: center;
  }

  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--ui-mutedText);
  }

  h3 {
    font-size: 20px;
    display: inline-block;
    margin-bottom: 0px;
  }

  .articleContainer {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        @include vars.mediaQuery('md') {
          max-width: calc(33% - 8px);
        }

        @include vars.mediaQuery('xl') {
          max-width: calc(25% - 8px);
        }

        width: 100%;
        margin-bottom: 4px;
        list-style: none;
        margin: 4px;

        &:hover {
          color: var(--ui-hover);
        }
      }
    }

    .articleHeader {
      margin-top: 32px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  .noArticles {
    max-width: fit-content;
    margin: auto;
    margin-top: 48px;
  }
}

.breadcrumbs {
  margin: 30px auto 0px auto;

  a {
    color: var(--ui-mutedText);
  }
}