@use 'ui/styles/variables' as vars;

.AboutContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2rem;

  @include vars.mediaQuery('md') {
    padding-left: 50px;
    padding-top: 0;
  }
}

.Title {
  font-size: 1.25em;
  color: var(--theme-palette-text-primary);
  font-weight: bold;
  margin: 8px 0;
}

.Label {
  font-size: 16px;
  font-weight: bold;
  color: #696969;
  min-width: 150px;
}

.Details {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.DetailLine {
  display: flex;
  flex-direction: row;
}

.Term {
  font-weight: normal;
}

.PublicationLink {
  color: var(--theme-palette-text-primary);
  font-size: 16px;
  font-weight: normal;
}

.Advertisement {
  align-self: center;
}
.AdvertisementContainer {
  justify-content: center;
}
.PublicationInfo {
  background: #fafafa;
  border-radius: 24px;
  border: 1px solid var(--theme-palette-primary);
  display: inline-block;
  color: var(--theme-palette-primary);
  font: italic 700 .8125em Georgia, times new roman, Times, serif;
  line-height: normal;
  height: 16px;
  line-height: 1.35em;
  text-align: center;
  width: 16px;
  cursor: pointer;
  margin-left: 4px;

  &:hover {
    background: var(--theme-palette-primary);
    color: #fff;
  }
}