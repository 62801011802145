@use "ui/styles/variables" as vars;

.Actions {
  display: flex;
  justify-content: left;
  gap: 12px;
  padding: 4px;
  flex-wrap: wrap;

  @include vars.mediaQuery("md") {
    flex-direction: row;
  }

  @media print {
    display: none;
  }
}