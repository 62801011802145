@use 'ui/styles/variables' as vars;

.ClippingsSearchBox {
  order: 1;
  margin-left: auto;
  padding: 10px 0px;
  flex: 100%;

  @include vars.mobileOnly() {
    // this is when it needs the margin-left to push it to the right
    flex: unset;

    &:global(.mobileSearchFocused) {
      flex: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: row;
    max-width: 450px;
    margin-left: auto;

    @include vars.mobileOnly() {
      width: 100%;
      max-width: 100%;
    }
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }

  input+button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.SearchField {
  display: flex;
  flex: 1;
  padding-left: 10px;
}

.SearchLabel {
  font-weight: bold;
  padding-right: 8px;
  position: relative;
  top: 7px;
}

.Disabled {
  fill: var(--ui-disabledText)
}