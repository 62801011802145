@use 'ui/styles/variables' as vars;

.pageContainer {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  padding: 16px;

  h1 {
    text-align: center;
  }

  .YearList {
    max-width: 900px;
  }

  .breadcrumbs {
    margin: 32px auto 0px auto;
    max-width: 900px;

    a {
      color: var(--ui-mutedText);
    }
  }
}