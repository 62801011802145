.ImageContainer {
  position: relative;
  height: 300px;
  width: 100%;
  margin-bottom: 40px;
  background-color: var(--background-gray);
}

.ClipImage {
  object-fit: contain;
}

.ShareList {
  align-items: baseline;
  display: flex;
  justify-content: center;
  width: 350px;
  margin-bottom: 24px;
  gap: 15px;
}

.ItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
  background-color: transparent;
  gap: 5px;

  button {
    display: flex;
    align-items: center;
  }

}

.ShareLink {
  color: var(--core-ink);
  font-weight: bold;
}