@use 'ui/styles/variables' as vars;

.NewsPageSearch {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #e0f0fa;
  padding: 12px;
  border-radius: 5px;
  width: 100%;

  @include vars.mediaQuery('md') {
    max-width: 300px;
  }

}

.SearchInput {
  width: 100%;
  border-radius: .375em 0 0 .375em;
  border: none;

  &:focus {
    box-shadow: none;
    border: none;
  }

}

.Reset {
  position: absolute;
  background: none;
  top: 18px;
  right: 16px;
}

.InputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

.FindContainer {
  display: flex;
  align-items: center;
  border-radius: 0 30px 30px 0;
  width: 100%;

  button {
    border-radius: 0 32px 32px 0 !important;
    height: 58px !important;
  }

}

.HitCount {
  font-size: 14px;
  line-height: 10px;
  margin-bottom: 12px;
  color: var(--alert-danger);

  &.matches {
    color: var(--alert-success);
  }
}

.Label {
  display: flex;
  align-items: center;
  width: 100%;
}