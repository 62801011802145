@use 'ui/styles/variables' as vars;

.MoreClippingsContainer {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.noClippings {
  color: var(--ui-mutedText);
  text-align: center;
}