@use 'ui/styles/variables' as vars;

.Side {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding-left: 0;

  @include vars.mediaQuery('md') {
    padding-left: 26px;
    flex: 0 0 33%;
  }

  @include vars.mediaQuery('lg') {
    flex: 0 0 auto;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }
}

.SideList {
  li {
    margin: 8px 0;
  }
}