.Popover {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  filter: drop-shadow(0 0 6pt #00000029);

  .popover-arrow::before {
    border: none;
  }

}

.Menu {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  background-color: var(--ui-divider);
  gap: 1px;
  overflow: hidden;

  li {
    list-style: none;
    margin-left: none;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    background-color: #fff;
    width: 100%;
    height: 40px;
  }
}