@use 'ui/styles/variables' as vars;

.Container {
  max-width: 540px;
  align-self: center;
}

.Title {
  color: var(--core-red);
}

.SubHeading {
  font-size: 1.125em;
  font-weight: bold;
}

.Form {
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;

  @include vars.mediaQuery('md') {
    max-width: 70%;
  }
}

.Icon {
  margin-left: 4px;
  height: 1em;
}

.Links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.Link {
  margin-bottom: 12px;
}