@use 'ui/styles/variables' as vars;

.Panel {
  background-color: var(--background-beige);
}

.Tabs {
  margin: 0 auto;
  max-width: 1200px;
}

.Container {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;

  @include vars.mediaQuery('md') {
    padding: 16px;
    flex-direction: row;
    gap: 32px;
  }

  .ProfileImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 12px;

    @include vars.mediaQuery('md') {
      padding: 16px 0px;
    }

    img {
      height: 120px;
      width: 120px;
      border-radius: 100%;
    }

    .changePhoto {
      text-align: center;
    }
  }

  .Details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    h1 {
      margin: 4px 0px;
    }

    ul {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .memberCommunications {
      gap: 4px;
    }
  }
}