@use 'ui/styles/variables' as vars;

.clippingList {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px 24px 0px;
  gap: 16px;

  @include vars.mediaQuery('md') {
    margin: 20px 8px 24px 0px;
  }

  .tile {
    width: 100%;
    max-width: 375px;

    @include vars.mediaQuery('sm') {
      width: calc(50% - 8px) !important;
    }

    @include vars.mediaQuery('lg') {
      width: calc(33% - 16px) !important;
    }
  }
}

.noClippings {
  text-align: center;
  margin: 48px 0px;

  span {
    color: var(--ui-mutedText);
    display: inline-block;
    padding: 12px 24px;
    background-color: var(--background-lighter);
    border: 1px solid var(--background-gray);
  }
}

.recentClippingsHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    margin-right: 20px;
    font-size: .9rem;
  }
}