.ClippingLink {
  width: 100%;
  position: relative;
  display: inline-block;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  grid-row: span 2 / span 2;

  &:focus-visible {
    outline-offset: 0px;
  }
}

.ClippingTitle {
  color: var(--core-red);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;

  &:focus-visible {
    outline-offset: 2px;
  }

  &:hover {
    color: var(--alert-dangerDark);
    text-decoration: underline;
  }

}

.PublicationDetails {
  padding: 8px 8px 8px 8px;
  overflow: hidden;
  gap: 40px;
  justify-content: space-between;
}

.UpperDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.LowerDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Details {
  margin: 16px 8px 0 8px;
}

.ImageContainer {
  width: 100%;
  position: relative;
  background-color: var(--background-lighter);
}

.Publication {
  font-size: 14px;
  display: flex;
}

.ActionsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4px;
  height: 32px;
}

.Tags {
  margin-top: 8px;
}

.TopicTags {
  display: flex;
  align-items: center;
}
