@use 'ui/styles/variables' as vars;

.articlePage {
  h1 {
    text-align: center;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--ui-mutedText);
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }

  .articleContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    @include vars.mediaQuery('md') {
      flex-direction: row;
      align-items: start;
    }

    img {
      min-width: 236px;
      max-width: 236px;
      max-height: 236px;

      @include vars.mediaQuery('md') {
        margin-top: 100px;
      }
    }

    .articleDetails {
      table {
        margin: auto;

        @include vars.mediaQuery('md') {
          margin-left: 64px;
        }

        th {
          text-align: right;
          padding-right: 8px;
        }
      }
    }
  }
}

.interstitialBlock {
  display: flex;
  background-color: var(--core-ink);
  color: #fff;
  padding: 32px;
  align-items: center;
  margin: 30px 0;
  height: 120px;
  position: relative;

  @include vars.mediaQuery('md') {
    margin: 30px;

    &:before {
      display: block;
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-right: 30px solid var(--core-ink);
      content: '';
      left: -30px;
      top: 0;
      right: auto;
      position: absolute;
    }
  }

  p {
    margin: 0 16px;
  }
}