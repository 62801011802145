@use 'ui/styles/variables' as vars;

.Container {
  display: flex;
  flex-direction: column;
  max-width: 790px;
  margin: 0 auto;

  @include vars.mediaQuery('md') {
    flex-direction: row;
  }
}

.Main {
  display: flex;
  flex-direction: column;
  flex: 7;
  gap: 24px;
  margin-bottom: 24px;

  h1 {
    margin-top: 0;
    color: var(--core-red)
  }

  @include vars.mediaQuery('md') {
    margin-bottom: 0
  }
}

.Next {
  padding: 16px;
  background-color: var(--background-beige);
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;

  @include vars.mediaQuery('md') {
    flex-direction: row;
    justify-content: space-around;
  }



  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.Checks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    padding-left: 0px;
    display: flex;
    gap: 4px;
    align-items: flex-start;
    line-height: 16px;
    min-width: fit-content;

    &:before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDEwIDEwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMCAxMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNDMTQxMzkiIGQ9Ik01IDBjMi44IDAgNSAyLjIgNSA1cy0yLjIgNS01IDVTMCA3LjggMCA1IDIuMiAwIDUgMHoiLz48cG9seWdvbiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSI4IDIgNCA2IDIgNCAxIDUgNCA4IDkgMyAiLz48L3N2Zz4=);
      background-position: 3px 3px;
      background-repeat: no-repeat;
      background-size: 10px;
      min-height: 16px;
      min-width: 16px;
      display: flex;
    }

  }
}

.Leaders {
  list-style: none;
  max-width: 60em;
  padding: 0;

  li {
    margin-bottom: 1em;

    .Leader {
      display: flex;

      div:first-child {
        color: var(--ui-mutedText);
        flex-grow: 0;
      }

      .dot {
        border-bottom: dotted 3px var(--ui-mutedText);
        flex-grow: 1;
        height: 1em;
        margin: 0 5px 0 5px;
      }

      div:last-child {
        flex-grow: 0;
      }
    }
  }
}