@use 'ui/styles/variables' as vars;

.Icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  opacity: 1 !important;
}

.Icon path {
  fill: var(--bs-dropwdown-link-color);
}

.InputWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.InputWrap.EditImage {
  flex-direction: row;
  gap: 1rem;
}

.EditImage+.Note {
  margin-top: 0;
}

.Note {
  margin-top: 1.75rem;
}

.Error {
  border: 1px solid var(--alert-danger) !important;
}

.ErrorMessage {
  color: var(--alert-danger);
  margin-top: 0.5rem;
  font-size: 0.875rem;
  display: flex;

  svg {
    height: 21px;
    width: 21px;
    margin-right: 0.25rem;
  }

  path {
    fill: var(--alert-danger);
  }
}