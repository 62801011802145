@use 'ui/styles/variables' as vars;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0px 16px;

  &.top {
    background-color: var(--background-beige);
  }

  @include vars.mediaQuery('md') {
    padding: 32px 32px 0px 32px;
  }

  .section {
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 1200px;

    @include vars.mediaQuery('md') {
      flex-direction: row;
      align-items: flex-start;
    }

    h2 {
      font-size: 1.25rem;
      padding-bottom: 8px;
      margin-bottom: 0;
      margin-top: 32px;

      &:first-of-type {
        margin-top: 8px;
      }

    }

    .info {
      min-width: 300px;

      @include vars.mediaQuery('md') {
        width: 33%;
        max-width: 354px;
        margin-left: 42px;
        margin-top: 0px;
        position: sticky;
        top: 16px;
      }

      .card {
        background-color: var(--background-lighter);
        padding: 20px 16px 24px 16px;
        border-radius: 4px;
        box-shadow: 0 1px 3px #00000029;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h2 {
          font-size: 1rem;
          font-weight: bold;

          &:first-of-type {
            margin-top: 0px;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            align-items: center;
          }

          svg {
            margin-right: 4px;
          }
        }

        .sourceInformation {
          font-size: 15px;
          color: var(--ui-mutedText);
          line-height: 1.33;
          -webkit-user-select: all;
          user-select: all;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 32px;

      p {
        margin: 0 0 1rem;
      }

      .breadcrumbContainer {
        margin-top: 32px;
      }
    }

    .map {
      height: 75vh;
      max-width: 100%;
      width: 1200px;
      margin-bottom: 16px;
    }

    .coverImage {
      padding: 8px;

      img {
        border-radius: 4px;

        @include vars.mediaQuery('md') {
          object-fit: cover;
          height: auto;
          width: 100%;
        }
      }

      @include vars.mediaQuery('md') {
        flex: 0 0 auto;
        margin-right: 32px;
        width: 300px;
      }
    }

    .publication {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      @include vars.mediaQuery('lg') {
        flex: 1 0 0%;
      }

      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 4px;

      @include vars.mediaQuery('md') {
        align-items: flex-start;
      }

      h1 {
        font-size: 2em;
        margin: 0px;
        text-align: center;

        @include vars.mediaQuery('md') {
          font-size: 2.5em;
          text-align: left;
        }

        &.long {
          font-size: 1.75em;
        }

        .noBreak {
          white-space: nowrap;

          svg {
            margin-left: -8px;
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
        }

        svg {
          margin-right: 4px;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-height: 40px;
        margin: 8px 0px;
        width: 100%;
        gap: 16px;

        @include vars.mediaQuery('md') {
          justify-content: flex-start;
        }
      }
    }
  }

  .tabs {
    display: flex;
    width: 100%;
    max-width: 1200px;
  }
}